<template>
  <div></div>
</template>

<script setup lang="ts">
const online = useOnline()

const router = useRouter()

const isOffline = ref(false)

watch(online, () => {
  if (!online.value) {
    isOffline.value = true
    throw createError({ statusCode: 503, statusMessage: 'Offline', fatal: true })
  } else if (isOffline.value) {
    isOffline.value = false
    router.go(0)
  }
})

onMounted(() => {
  isOffline.value = !online.value
})
</script>

<style scoped></style>
